<template>
  <AtroContent :class="['sticky top-0 z-10 h-12 w-full px-4 py-2']">
    <slot />
  </AtroContent>
</template>

<script setup lang="ts">
const globalState = useGlobalState()

onMounted(() => {
  globalState.value.appBannerShown = true
})

onUnmounted(() => {
  globalState.value.appBannerShown = false
})
</script>
