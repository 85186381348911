import html2canvas from 'html2canvas'
import { toCamelCase, toSnakeCase } from 'js-convert-case'

type ActionHandler = Record<FlowActionType, (payload?: any, page?: Page) => any>

type PayloadConfig = {
  actionSlug: FlowActionType
  body?: any
  confirm?: boolean
}

function findPage(pages: Page[], slug: string) {
  return pages.find((p) => p.slug === slug)
}

function useBRFlowActions(config: {
  baseActions: ActionHandler
  deps: Ref<{ flow: Flow }>
  handleAPIError: (e: any) => void
  view?: 'flow' | 'orgFlow' | 'userFlow'
}) {
  const { baseActions, deps, handleAPIError, view } = config
  const copyText = useCopyText()
  const downloadPDF = useDownloadPDF()
  const globalState = useGlobalState()
  const router = useRouter()
  const toast = useToast()
  const { confirmAction } = useActionConfirm()
  const { getSession, signIn } = useAuth()
  const { confirmValidSubscriptionPlan } = useFlowPaywall(deps.value.flow)
  const { clearDirty } = usePageDirtyTracking()
  const { pending } = usePageState()
  const { withPending, wrapWithPending } = usePendingWrap(pending)

  const { mutate: createOnboardFlow } =
    useMutation<OnboardingFlowResponse>('createOnboardFlow')

  const { mutate: saveOrgFlowData } = useMutation<OrgFlow>('saveOrgFlowData')
  const { mutate: saveUserFlowData } = useMutation<UserFlow>('saveUserFlowData')
  const { mutate: sendOrgFlowAction } = useMutation('sendOrgFlowAction')
  const { mutate: sendUserFlowAction } = useMutation('sendUserFlowAction')

  const partnerSlug = computed(() => router.currentRoute.value.query.partner)

  const onCreateOnboardFlow = wrapWithPending(async function () {
    const dataToPost = deps.value.flow.data.infoFields as Record<string, any>
    if (partnerSlug.value) {
      dataToPost.org.partner = partnerSlug
    }
    try {
      const data = await createOnboardFlow({
        orgFlow: { flowId: deps.value.flow.id },
        ...dataToPost,
      })
      await signIn('credentials', {
        callbackUrl: '/',
        redirect: false,
        type: 'claimToken',
        data: JSON.stringify(data.auth),
      })
      const updatedSession = await getSession()
      await navigateTo(
        {
          path: `/orgs/${updatedSession?.orgs[0].slug}/org-flows/${data.orgFlow.id}/setup`,
          query: { onboarding: 'true' },
        },
        { replace: true },
      )
    } catch (e) {
      handleAPIError(e)
    }
  })

  async function savePageData(page: Page) {
    let promise
    try {
      if (view === 'orgFlow') {
        promise = saveOrgFlowData(deps.value.flow.data, {
          params: {
            id: deps.value.flow.id,
          },
          query: {
            page: page.slug,
          },
        })
      } else if (view === 'userFlow') {
        promise = saveUserFlowData(deps.value.flow.data, {
          params: { id: deps.value.flow.id },
          query: {
            page: page.slug,
          },
        })
      }
      if (!page.async) {
        pending.value = true
        await promise
      } else {
        promise?.catch(handleAPIError)
      }
    } catch (e) {
      handleAPIError(e)
      pending.value = false
      throw e
    }
    pending.value = false
  }

  function sendAction(payload: any, params?: any) {
    if (view === 'orgFlow') {
      return sendOrgFlowAction(payload, { params })
    } else if (view === 'userFlow') {
      return sendUserFlowAction(payload, { params })
    }
    return Promise.resolve()
  }

  const actions: ActionHandler = {
    complete: async (payload, page) => {
      clearDirty()
      const query = {
        ...router.currentRoute.value.query,
      }
      if (view === 'orgFlow') {
        confirmValidSubscriptionPlan(async () => {
          await savePageData(page as Page)
          // TODO: move to after action handler
          globalState.value.flowCompleted = true
          navigateToWithOrg(
            {
              query,
              path: `/org-flows/${deps.value.flow.id}`,
            },
            { replace: true },
          )
        })
      } else if (view === 'userFlow') {
        await savePageData(page as Page)
        globalState.value.flowCompleted = true
        navigateToWithOrg(
          { query, path: `/user-flows/${deps.value.flow.id}` },
          { replace: true },
        )
      }
    },
    instantiate_onboard_flow: () => onCreateOnboardFlow(),
    open_page: (payload) => {
      const pageToOpen = findPage(deps.value.flow.pages, payload)
      if (pageToOpen) {
        if (view === 'orgFlow') {
          navigateToWithOrg(
            `/org-flows/${deps.value.flow.id}/setup?page=${pageToOpen.slug}`,
          )
        } else {
          navigateToWithOrg(
            `/user-flows/${deps.value.flow.id}/setup?page=${pageToOpen.slug}`,
          )
        }
      } else {
        console.error(
          'MISSING PAGE SLUG:',
          payload,
          'AVAILABLE SLUGS:',
          deps.value.flow.pages.map((page) => page.slug),
        )
      }
    },
    refresh_connection: async (paylaod) => {
      try {
        await withPending(
          sendAction(null, {
            id: deps.value.flow.id,
            actionSlug: 'fetch_data',
          }),
        )
        if (typeof paylaod === 'function') paylaod()
        toast.success(
          'Data refreshed. Depending on the service, updates may be delayed.',
        )
      } catch (e) {}
      return true
    },
    reset: (body) => {
      actions.send_action({
        body,
        actionSlug: 'reset',
        confirm: true,
      })
    },
    save_page_data: async (payload, page) => {
      await savePageData(page as Page)
    },
    send_action: async (payload: PayloadConfig) => {
      const send = async () => {
        const resp = await sendAction(payload.body, {
          id: deps.value.flow.id,
          actionSlug: payload.actionSlug,
        })
        baseActions.refresh_data()
        return resp
      }
      if (payload.confirm) {
        confirmAction(
          payload.actionSlug,
          deps.value.flow,
          wrapWithPending(async () => {
            try {
              await send()
              return true
            } catch (e) {
              handleAPIError(e)
              return false
            }
          }),
        )
      } else {
        try {
          return await send()
        } catch (e) {
          handleAPIError(e)
        }
      }
    },
    set_autopilot: (body) => {
      actions.send_action({
        body,
        actionSlug: 'set_autopilot',
      })
    },
    set_public_page: (body) => {
      actions.send_action({
        body,
        actionSlug: 'set_public_page',
      })
    },
    submit: async (payload, page) => {
      await savePageData(page as Page)
    },
    update_assignees: (payload, page) => savePageData(page as Page),

    // UNIQUE FLOW ACTIONS
    // SECURITY POLICY
    download: () =>
      downloadPDF(
        toSnakeCase(deps.value.flow.title),
        deps.value.flow.data.policy as string,
      ),
    reset_policy: () => {
      confirmAction('reset_policy', deps.value.flow, () => {
        deps.value.flow.data = {
          ...deps.value.flow.data,
          policyDraft: deps.value.flow.data.policyTemplate,
        }
        return true
      })
    },
    share_policy: () =>
      copyText(
        `${window.location.origin}/policies/${deps.value.flow.id}`,
        'Share link copied!',
      ),
  }
  return actions
}

function useBRFrameworkActions(config: {
  state: Ref<Record<string, any>>
  deps: any
}) {
  const { openModal } = useModal()

  const actions: ActionHandler = {
    open_framework_item: (payload) => {
      const fetchRequest = useData<FrameworkItem>('framworkItem', payload)
      openModal('pagesController', {
        props: {
          fetchRequest,
          actionsConfig: { actionsToInclude: ['framework'] },
        },
      })
    },
  }

  return actions
}

export default function useBRActions(config: {
  actionsToInclude?: ['flow'] | ['framework']
  deps?: Ref<any>
  state?: Ref<Record<string, any>>
  afterAction?: (type: FlowActionType, payload?: any, actionReturn?: any) => any
  beforeAction?: (
    type: FlowActionType,
    payload?: any,
  ) => boolean | Promise<boolean>
  overrideActions?: Record<FlowActionType, (payload?: any, page?: Page) => any>
  [key: string]: any
}) {
  const {
    afterAction,
    beforeAction,
    deps,
    overrideActions,
    state,
    actionsToInclude = [''],
  } = config
  const connectIntegration = useIntegrationConnect()
  const copyText = useCopyText()
  const router = useRouter()
  const sentry = useSentry()
  const toast = useToast()
  const { analytics } = useAnalytics()
  const { openMarketplace } = useMarketplace()
  const { openModal } = useModal()
  const { pageForm, pending } = usePageState()
  const { triggerPaywall } = usePaywall()
  const { withPending, wrapWithPending } = usePendingWrap(pending)

  const { mutate: createOrgFlow } = useMutation<OrgFlow>('createOrgFlow')

  function afterModalClose() {
    removeQueryParam(router.currentRoute.value, ['action', 'payload'])
    actionHandlers.refresh_data()
  }

  function handleAPIError(e: any) {
    if (pageForm.value) handleFormError(e, pageForm.value)
    // paywall trigger
    switch (e.statusCode) {
      case 402:
        triggerPaywall()
        break
      case 403:
        toast.error("You don't have permissions to complete that action")
        break
      default:
        toast.error('An error occured, please try again')
        break
    }
  }

  const onCreateOrgFlow = wrapWithPending(async function (id: string) {
    try {
      const data = await createOrgFlow({ flowId: id || deps?.value.flow.slug })
      await navigateToWithOrg(`/org-flows/${data.id}/setup`, {
        replace: true,
      })
    } catch (e) {
      handleAPIError(e)
    }
  })

  const baseActions: ActionHandler = {
    claim_account: () => navigateTo('/signup/connect'),
    copy_page_url: () => copyText(window.location.href, 'Share link copied!'),
    delete_item: (body) => {
      actionHandlers.send_action({
        body,
        actionSlug: 'delete_item',
        confirm: true,
      })
      baseActions.refresh_data()
    },
    instantiate_org_flow: (payload: string) => onCreateOrgFlow(payload),
    notify: (body) => {
      actionHandlers.send_action({
        body,
        actionSlug: 'notify',
        confirm: true,
      })
    },
    notify_many: (body) => {
      actionHandlers.send_action({
        body,
        actionSlug: 'notify_many',
        confirm: true,
      })
    },
    oauth_connect: async (payload) => {
      try {
        await withPending(connectIntegration(payload as OAuthService))
        baseActions.refresh_data()
      } catch (e: any) {
        const errorDetails = e.data?.errors?.detail
        if (e.message === 'cancelled') {
          toast.error('Connection cancelled')
          // Google connection will throw 403 when connecting a non-admin account
        } else if (e.statusCode === 403) {
          switch (errorDetails) {
            case 'license required':
              toast.error(
                'Invalid subscription type. Microsoft Entra ID P1 subscription may be required.',
              )
              break
            default:
              toast.error(
                'Connection failed! Account selected is not an administrator.',
              )
              break
          }
        } else {
          toast.error('Connection error!')
        }
        throw e
      }
    },
    open_flow: (payload: { type: 'org' | 'user'; id: string }) => {
      if (payload?.type === 'org') {
        navigateToWithOrg(`/org-flows/${payload.id}/setup`)
      } else if (payload?.type === 'user') {
        navigateToWithOrg(`/user-flows/${payload.id}/setup`)
      } else {
        console.error(
          "ACTION open_flow missing payload: {type: 'org' | 'user', id: string}",
        )
      }
    },
    open_link: (payload) => navigateTo(payload),
    open_marketplace: (payload) =>
      openMarketplace({ ...(payload ? { slug: payload } : {}) }),
    open_modal: (slug, parentPage) => {
      const pageToOpen = config.pages && findPage(config.pages, slug)
      if (pageToOpen) {
        openModal('flowPage', {
          eventHandlers: {
            action: (payload: [FlowAction, Page]) => {
              onAction(...payload)
            },
          },
          afterClose: afterModalClose,
          props: {
            deps,
            page: pageToOpen,
            data: state?.value,
          },
        })
      } else {
        openModal(toCamelCase(slug) as ModalName, {
          eventHandlers: {
            action: (action: FlowAction) => {
              if (parentPage) onAction(action, parentPage)
            },
          },
          afterClose: afterModalClose,
          props: {
            deps,
            data: state?.value,
          },
        })
      }
    },
    refresh_data: (payload?: string | string[]) => {
      refreshNuxtData(payload)
    },
    screenshot: async (fileName = 'screenshot') => {
      const el = document.querySelector<HTMLElement>('#flow-main-content')
      if (el) {
        const padding = 96
        const elHeight = el.offsetHeight
        const cardHeight =
          document.querySelector<HTMLElement>('#flow-main-content-card')
            ?.offsetHeight || 0
        const height = Math.max(elHeight, cardHeight) + padding
        const width = el.offsetWidth + padding
        const elBoundingRect = el.getBoundingClientRect()
        const canvas = await html2canvas(el, {
          height,
          width,
          foreignObjectRendering: true,
          x: -(elBoundingRect.left + padding / 6),
          y: -(elBoundingRect.top + padding / 6),
        })
        const img = canvas.toDataURL('image/png')
        saveAs(img, `${fileName}.png`)
      }
    },
  }

  const flowActions = actionsToInclude?.includes('flow')
    ? useBRFlowActions({ ...config, baseActions, handleAPIError })
    : {}

  const frameworkActions = actionsToInclude?.includes('framework')
    ? useBRFrameworkActions({ ...config, baseActions, handleAPIError })
    : {}

  const actionHandlers: ActionHandler = {
    ...baseActions,
    ...flowActions,
    ...frameworkActions,
  }

  async function onAction(action: FlowAction, actionPage?: Page) {
    if (!action) return

    const actionName = toSnakeCase(action.type) as FlowActionType
    const handler = actionHandlers[actionName] || overrideActions?.[actionName]

    // @ts-ignore
    if (handler) {
      console.log(
        'ACTION:',
        actionName,
        'PAYLOAD:',
        action.data,
        'actionPage',
        actionPage,
      )
      try {
        const beforeActionResponse = beforeAction
          ? await beforeAction(action.type, action.data)
          : true
        if (beforeActionResponse !== false) {
          const response = await handler(action.data, actionPage)
          if (afterAction) afterAction(action.type, action.data, response)
          analytics.value?.track('ba-action', {
            actionType: action.type,
            ...(actionPage ? { page: actionPage.slug } : {}),
          })
        }
      } catch (e) {
        console.error('ACTION FAILED', e)
        sentry.captureException(e)
      }
    } else {
      console.warn('UNHANDLED ACTION: ', actionName)
    }
  }

  return onAction
}

export type ActionsConfig = Parameters<typeof useBRActions>[0]
