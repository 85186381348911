<template>
  <AtroCard class="!bg-atro-purple/5 !p-10 !pt-4" size="full">
    <FlowAtomTitle title="Results summary" margin="sm" />
    <AtroContent class="w-full gap-6" items="strech" wrap="nowrap">
      <!-- RISK COLUMN -->
      <AtroCard
        class="!w-[268px] shrink-0 justify-between space-y-14 !rounded-2xl !p-6"
      >
        <AtroContent class="text-center" direction="col" items="center">
          <AtroHeading
            semibold
            class="text-atro-slate-purple"
            text="Risk Score"
          />
          <AtroScoreGauge :score class="mt-6" />
          <AtroSpan class="mt-4 text-atro-gray" size="xxs"
            >The industry average score for a secure company is
            <span class="text-atro-green">685</span></AtroSpan
          >
          <AtroButton
            v-if="viewAnswersAction"
            class="mt-2"
            text="View Answers"
            type="flat"
            @click="emit('action', viewAnswersAction)"
          />
        </AtroContent>

        <RiskProfileChart
          :data="[
            {
              label: 'You',
              position: riskProfile.org,
              primary: true,
            },
            { label: 'Average', position: riskProfile.average },
          ]"
          x-label="Likelihood"
          y-label="Impact"
        />

        <AtroCard
          v-if="financialLossPotential"
          class="!rounded-2xl !bg-atro-red-15 !p-4 text-center"
        >
          <AtroHeading
            semibold
            class="text-atro-red"
            size="xl"
            :text="financialLossPotential"
          />
          <AtroSpan
            semibold
            class="text-atro-gray-2"
            size="lg"
            text="Financial Loss potential"
          />
        </AtroCard>
      </AtroCard>

      <AtroContent class="flex-1 gap-8" direction="col">
        <AtroCard
          class="w-full !flex-row flex-nowrap items-center justify-between gap-14 !rounded-2xl border !p-6"
        >
          <!-- SECURITY ALERTS ROLLUP -->
          <AtroContent class="flex-1" direction="col" items="center">
            <AtroContent class="space-x-2" items="center">
              <AtroHeading
                semibold
                class="text-atro-slate-purple"
                size="xxl"
                :text="(alerts.high + alerts.medium + alerts.low).toString()"
              />
              <AtroContent
                class="size-10 rounded-full bg-atro-warm-gray-2"
                items="center"
                justify="center"
              >
                <AtroIcon
                  class="size-6 text-atro-red"
                  name="triangle-exclamation"
                />
              </AtroContent>
            </AtroContent>
            <AtroSpan
              semibold
              class="mt-2 text-atro-gray"
              size="lg"
              text="Security Alerts Detected"
            />
            <AtroContent class="mt-8 w-full gap-2">
              <!-- HIGH RISK -->
              <AtroContent
                class="flex-1 space-y-1 rounded-lg bg-atro-warm-gray-2 p-4"
                direction="col"
                items="center"
                justify="center"
              >
                <AtroHeading
                  semibold
                  class="!leading-none text-atro-red"
                  size="xl"
                  :text="alerts.high.toString()"
                />
                <AtroSpan
                  semibold
                  class="text-atro-gray"
                  size="sm"
                  text="High Risk"
                />
              </AtroContent>

              <!-- MEDIUM RISK -->
              <AtroContent
                class="flex-1 space-y-1 rounded-lg bg-atro-warm-gray-2 p-4"
                direction="col"
                items="center"
                justify="center"
              >
                <AtroHeading
                  semibold
                  class="!leading-none text-atro-yellow"
                  size="xl"
                  :text="alerts.medium.toString()"
                />
                <AtroSpan
                  semibold
                  class="text-atro-gray"
                  size="sm"
                  text="Medium Risk"
                />
              </AtroContent>

              <!-- LOW RISK -->
              <AtroContent
                class="flex-1 space-y-1 rounded-lg bg-atro-warm-gray-2 p-4"
                direction="col"
                items="center"
                justify="center"
              >
                <AtroHeading
                  semibold
                  class="!leading-none text-atro-green"
                  size="xl"
                  :text="alerts.low.toString()"
                />
                <AtroSpan
                  semibold
                  class="text-atro-gray"
                  size="sm"
                  text="Low Risk"
                />
              </AtroContent>
            </AtroContent>
          </AtroContent>

          <!-- ALERT DISTRIBUTION -->
          <AtroContent>
            <DataDistribution
              :data="alertsDistribution"
              title="Alert Distribution"
              x-label="LIKELIHOOD"
              y-label="IMPACT"
            />
          </AtroContent>
        </AtroCard>

        <!-- ATTACK LIKELIHOOD -->
        <AtroContent class="w-full" justify="center">
          <AtroHeading
            semibold
            class="text-atro-slate-purple"
            text="Attack Likelihood"
          />
          <div class="mt-3 grid w-full grid-cols-3 gap-4">
            <AtroCard
              v-for="attackLikelihood in attackLikelihoods"
              :key="attackLikelihood.key"
              class="w-[220px] items-center !justify-between !rounded-2xl border text-center"
            >
              <AtroSpan
                semibold
                class="text-atro-slate-purple"
                :text="getAttackLikelihoodTitle(attackLikelihood.key)"
              />
              <NuxtImg
                height="128"
                width="172"
                :src="`/assessment/attack-likelihoods/${attackLikelihood.key}.svg`"
              />
              <div class="mt-6 w-full space-y-2">
                <RiskThermometer :risk="attackLikelihood.riskLevel" />
                <AtroSpan
                  semibold
                  size="sm"
                  :class="[
                    'block capitalize',
                    getRiskColor(attackLikelihood.riskLevel, 'text'),
                  ]"
                  :text="`${attackLikelihood.riskLevel} Likelihood`"
                />
              </div>
            </AtroCard>
          </div>
        </AtroContent>
      </AtroContent>
    </AtroContent>
  </AtroCard>
</template>

<script setup lang="ts">
import { getRiskColor } from '@atro/components'

export interface Props {
  alerts: {
    high: number
    medium: number
    low: number
  }
  alertsDistribution: number[]
  attackLikelihoods: {
    key: AttackLikelihoodKey
    riskLevel: RiskLevel
  }[]
  financialLossPotential: string
  riskProfile: {
    average: [number, number]
    org: [number, number]
  }
  score: number
  viewAnswersAction: FlowAction
}

defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
}>()

function getAttackLikelihoodTitle(key: AttackLikelihoodKey) {
  switch (key) {
    case 'data_breaches':
      return 'Data Breaches'
    case 'financial_loss':
      return 'Financial Loss'
    case 'intellectual_property_theft':
      return 'Intellectual Property Theft'
    case 'legal_consequences':
      return 'Legal Consequences'
    case 'operational_disruptions':
      return 'Operational Disruptions'
    case 'reputation_damage':
      return 'Reputation Damage'
  }
}
</script>
