export const apiPaths = {
  // ADMIN
  adminOrg: (params: { orgId: string }) => `/api/admin/orgs/${params.orgId}`,
  adminOrgPlan: (params: { orgId: string }) =>
    `/api/admin/orgs/${params.orgId}/plan`,
  adminOrgSubscriptionState: (params: { orgId: string }) =>
    `/api/admin/orgs/${params.orgId}/subscription-state`,
  adminOrgTrialEndsAt: (params: { orgId: string }) =>
    `/api/admin/orgs/${params.orgId}/trial-ends-at`,
  adminOrgs: () => '/api/admin/orgs',
  adminSubscriptionPlans: () => '/api/admin/plans',
  importContent: () => '/api/dev/import',

  // Dashboard
  dashboard: (params: { orgId: string }) =>
    `/api/orgs/${params.orgId}/dashboard`,

  // Device
  devices: (params: { orgId: string }) => `/api/orgs/${params.orgId}/devices`,
  deviceStatus: () => `/api/device/ui`,

  // FLOW API
  createRecommendedFlows: (params: { orgId: string }) =>
    `/api/orgs/${params.orgId}/onboarding/create-recommended-org-flows`,
  flow: (params: { id: string; orgId: string }) =>
    `/api/orgs/${params.orgId}/flows/${params.id}`,
  onboardingFlow: (params: { id: string }) =>
    `/api/onboarding_flows/${params.id}`,
  onboardingFlows: () => '/api/onboarding_flows',
  orgFlow: (params: { id: string; orgId: string }) =>
    `/api/orgs/${params.orgId}/org_flows/${params.id}`,
  orgFlows: (params: { orgId: string }) =>
    `/api/orgs/${params.orgId}/org_flows`,
  restartOrgFlow: (params: { id: string; orgId: string }) =>
    `/api/orgs/${params.orgId}/org_flows/${params.id}/restart`,
  sendOrgFlowAction: (params: {
    actionSlug: string
    id: string
    orgId: string
  }) =>
    `/api/orgs/${params.orgId}/org_flows/${params.id}/actions/${params.actionSlug}`,
  sendUserFlowAction: (params: {
    actionSlug: string
    id: string
    orgId: string
  }) =>
    `/api/orgs/${params.orgId}/user_org_flows/${params.id}/actions/${params.actionSlug}`,
  publicOrgFlow: (params: { id: string }) =>
    `/api/org_flows/${params.id}/public`,
  userFlow: (params: { id: string; orgId: string }) =>
    `/api/orgs/${params.orgId}/user_org_flows/${params.id}`,
  userFlows: (params: { orgId: string }) =>
    `/api/orgs/${params.orgId}/user_org_flows`,

  // FRAMEWORKS
  dashboardFramework: (params: { orgId: string; slug: string }) =>
    `${apiPaths.org(params)}/frameworks/${params.slug}/dashboard-component`,
  framworkItem: (params: { orgId: string; slug: string }) =>
    `${apiPaths.org(params)}/framework-items/${params.slug}`,

  // INTEGRATIONS
  initializeOAuth: (params: { orgId: string; service: OAuthService }) =>
    `/api/orgs/${params.orgId}/integrations/${params.service}/oauth2/init`,

  finalizeOAuth: (params: { orgId: string; service: OAuthService }) =>
    `/api/orgs/${params.orgId}/integrations/${params.service}/oauth2/finalize`,

  // ORG
  changeOwner: (params: { orgId: string }) => `${apiPaths.org(params)}/owner`,
  childOrgs: (params: { orgId: string }) =>
    `${apiPaths.orgs()}/${params.orgId}/orgs`,

  orgs: () => '/api/orgs',
  org: (params: { orgId: string }) => `${apiPaths.orgs()}/${params.orgId}`,
  orgReports: (params: { orgId: string }) => `${apiPaths.org(params)}/reports`,
  orgUsers: (params: { orgId: string }) => `${apiPaths.org(params)}/users`,
  orgUser: (params: { orgId: string; userId: string }) =>
    `${apiPaths.orgUsers(params)}/${params.userId}`,

  // CHILD ORG
  childOrg: (params: { childOrgId: string }) =>
    `/api/partners/orgs/${params.childOrgId}`,
  generateAssessmentResults: (params: { childOrgId: string }) =>
    `${apiPaths.childOrg(params)}/generate-assessment-results`,
  activateChild: (params: { childOrgId: string }) =>
    `${apiPaths.childOrg(params)}/activate`,

  // PARTNER
  prospects: (params: { orgId: string }) =>
    `/api/partners/${params.orgId}/prospects`,

  // POLICY TEMPLATES
  policyTemplate: (params: { orgId: string; id: string }) =>
    `/api/orgs/${params.orgId}/policy-flow-templates/${params.id}`,
  policyTemplates: (params: { orgId: string }) =>
    `/api/orgs/${params.orgId}/policy-flow-templates`,

  // SUBSCRIPTION
  subscriptionDowngrade: (params: { orgId: string }) =>
    `/api/orgs/${params.orgId}/subscription/downgrade`,

  // TEAM
  teamDashboard: (params: { orgId: string }) =>
    `/api/orgs/${params.orgId}/team-dashboard`,

  // USER
  profile: () => '/api/profile',
  userDetails: (params: { userId: string }) => `/api/user/${params.userId}`,

  // OTHER
  magicLink: () => `/api/auth/magic/request`,
  presignedS3: (params: { orgId: string; context: UploadContext }) =>
    `/api/orgs/${params.orgId}/uploads/${params.context}`,
}

export function getApiPath(
  name: keyof typeof apiPaths,
  params: any = undefined,
  query: Record<string, string> = {},
) {
  const path = apiPaths[name](params as any)
  const queryParams = new URLSearchParams(query).toString()
  return `${path}${queryParams ? `?${queryParams}` : ''}`
}

export const deleteResource = async <T>(
  route: string | null,
  {
    id = '',
    key = '',
  }: {
    id: string
    key?: string | null
  },
  removeFn?: (cache: any, id: string) => void,
) => {
  if (!route) return
  const { data: cache } = useNuxtData<any>(key || '')

  const response = await $delete(route)

  if (removeFn) {
    removeFn(cache, id)
  } else if (key && Array.isArray(cache.value)) {
    const itemIndex = cache.value?.findIndex((item: any) => item.id === id)
    typeof itemIndex === 'number' && cache.value?.splice(itemIndex, 1)
  }

  return response
}

export const createResource = async <T>(
  route: string | null,
  {
    body,
    key = '',
  }: {
    body?: any
    key?: string | null
  } = {},
) => {
  if (!route) return
  const { data: cache } = useNuxtData<any[]>(key || '')
  const response = await $post<T>(route, body)
  cache.value?.splice(0, 0, response)

  return response
}

export const updateResource = async <T>(
  route: string | null,
  {
    body,
    key = '',
  }: {
    body: any
    key?: string | null
  },
  method: 'PATCH' | 'PUT' = 'PATCH',
) => {
  if (!route) return
  const { data: cache } = useNuxtData<any[]>(key || '')
  const response =
    method === 'PATCH'
      ? await $patch<T>(route, body)
      : await $put<T>(route, body)

  if (key && Array.isArray(cache.value) && typeof response === 'object') {
    const itemIndex = cache.value?.findIndex(
      // @ts-ignore
      (item: any) => item.id === response?.id,
    )
    typeof itemIndex === 'number' &&
      itemIndex >= 0 &&
      cache.value?.splice(itemIndex, 1, response)
  }

  return response
}

export function refreshData(
  pathname: keyof typeof apiPaths | string,
  params = {},
) {
  const { org } = useCurrentOrg()
  const { user } = useCurrentUser()

  const pathParams = { orgId: org.value?.id, ...params }
  // @ts-ignore
  const url =
    apiPaths[pathname]?.(pathParams, {
      org: org.value,
      user: user.value,
    }) || pathname

  return refreshNuxtData(url)
}
