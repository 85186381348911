// ATRO BASE COMPONENTS
import {
  AtroHeading as Heading,
  AtroModalHeader as ModalHeader,
  AtroParagraph as Paragraph,
  AtroSpan as Span,
} from '@atro/components'

// ATOM COMPONENTS
import AwardBadge from '~/components/flow/atom/FlowAtomAwardBadge.vue'
import Badge from '~/components/flow/atom/FlowAtomBadge.vue'
import Button from '~/components/flow/atom/FlowAtomButton.vue'
import BarChart from '~/components/flow/atom/FlowAtomDataBarChart.vue'
import Card from '~/components/flow/atom/FlowAtomCard.vue'
import Container from '~/components/flow/atom/FlowAtomContainer.vue'
import Custom from '~/components/flow/atom/FlowAtomCustom.vue'
import DataCard from '~/components/flow/atom/FlowAtomDataCard.vue'
import DataItem from '~/components/flow/atom/FlowAtomDataItem.vue'
import DataStat from '~/components/flow/atom/FlowAtomDataStat.vue'
import Editor from '~/components/flow/atom/FlowAtomEditor.vue'
import FeatureList from '~/components/flow/atom/FlowAtomFeatureList.vue'
import Grid from '~/components/flow/atom/FlowAtomGrid.vue'
import InfoTooltip from '~/components/flow/atom/FlowAtomInfoTooltip.vue'
import List from '~/components/flow/atom/FlowAtomList.vue'
import ListItem from '~/components/flow/atom/FlowAtomListItem.vue'
import ListSubitems from '~/components/flow/atom/FlowAtomListItemSubitems.vue'
import Hero from '~/components/flow/atom/FlowAtomHero.vue'
import Html from '~/components/flow/atom/FlowAtomHtml.vue'
import Form from '~/components/flow/atom/FlowAtomForm.vue'
import Graphic from '~/components/flow/atom/FlowAtomGraphic.vue'
import Markdown from '~/components/flow/atom/FlowAtomMarkdown.vue'
import MenuItem from '~/components/flow/atom/FlowAtomMenuListItem.vue'
import OptionsGroup from '~/components/flow/atom/FlowAtomOptionsGroup.vue'
import OptionsGrouping from '~/components/flow/atom/FlowAtomOptionsGrouping.vue'
import Title from '~/components/flow/atom/FlowAtomTitle.vue'
import ProgressBar from '~/components/flow/atom/FlowAtomProgressBar.vue'
import Reader from '~/components/flow/atom/FlowAtomReader.vue'
import SignupCard from '~/components/flow/atom/FlowAtomSignupCard.vue'
import SolutionCard from '~/components/flow/atom/FlowAtomSolutionCard.vue'
import SolutionsList from '~/components/flow/atom/FlowAtomSolutionsList.vue'
import Spacer from '~/components/flow/atom/FlowAtomSpacer.vue'
import StatItem from '~/components/flow/atom/FlowAtomStatItem.vue'
import StatusCard from '~/components/flow/atom/FlowAtomStatusCard.vue'
import StatusList from '~/components/flow/atom/FlowAtomStatusList.vue'
import StepsProgress from '~/components/flow/atom/FlowAtomStepsProgress.vue'
import Tabs from '~/components/flow/atom/FlowAtomTabs.vue'
import TagList from '~/components/flow/atom/FlowAtomTagList.vue'
import TextBlock from '~/components/flow/atom/FlowAtomTextBlock.vue'
import WelcomeCTA from '~/components/flow/atom/FlowAtomWelcomeCTA.vue'
import WelcomeHero from '~/components/flow/atom/FlowAtomWelcomeHero.vue'

// GENERAL COMPONENTS
import Assign from '~/components/user/UserAssign.vue'
import UsersIncluded from '~/components/user/UserIncluded.vue'
import RollupCard from '~/components/RollupCard.vue'
import RollupCardList from '~/components/RollupCardList.vue'
import StatusItem from '~/components/StatusItem.vue'
import Quiz from '~/components/quiz/QuizContainer.vue'

// MOLOCULE COMPONENTS
import ActionCenter from '~/components/flow/mol/FlowMolActionCenter.vue'
import PhishingReportTable from '~/components/flow/mol/FlowMolPhishingRepprtTable.vue'
import Stats from '~/components/flow/mol/FlowMolStats.vue'
import StepList from '~/components/flow/mol/FlowMolStepList.vue'
import SecurityAssessmentResults from '~/components/flow/mol/FlowMolSecurityAssessmentResults.vue'
import TestReport from '~/components/flow/mol/FlowMolTestReport.vue'
import VendorAssignment from '~/components/flow/mol/FlowMolVendorAssignment.vue'

// ASSESSMENT COMPONENTS
import AssessmentCard from '~/components/flow/assessment/FlowAssessmentCard.vue'
import AssessmentRiskReportCard from '~/components/assessment/AssessmentRiskReportCard.vue'
import AssessmentSecurityFoundation from '~/components/assessment/AssessmentSecurityFoundation.vue'
import AssessmentResultsSummary from '~/components/assessment/AssessmentResultsSummary.vue'

// FRAMEWORK COMPONENTS
import FrameworkFlowGroup from '~/components/framework/FrameworkFlowGroup.vue'
import FrameworkItem from '~/components/framework/FrameworkItem.vue'

export default function useComponentForFlow(type: string) {
  const componentTypeMap = {
    // atoms
    award_badge: AwardBadge,
    badge: Badge,
    bar_chart: BarChart,
    button: Button,
    card: Card,
    container: Container,
    custom: Custom,
    data_card: DataCard,
    data_item: DataItem,
    data_stat: DataStat,
    editor: Editor,
    feature_list: FeatureList,
    form: Form,
    graphic: Graphic,
    grid: Grid,
    heading: Heading,
    hero: Hero,
    html: Html,
    info_tooltip: InfoTooltip,
    list: List,
    list_item: ListItem,
    list_subitems: ListSubitems,
    markdown: Markdown,
    menu_item: MenuItem,
    modal_header: ModalHeader,
    options_group: OptionsGroup,
    options_grouping: OptionsGrouping,
    paragraph: Paragraph,
    progress_bar: ProgressBar,
    reader: Reader,
    sidebar_item: MenuItem,
    signup_card: SignupCard,
    solution_card: SolutionCard,
    solutions_list: SolutionsList,
    spacer: Spacer,
    span: Span,
    stat_item: StatItem,
    status_card: StatusCard,
    status_item: StatusItem,
    status_list: StatusList,
    steps_progress: StepsProgress,
    tabs: Tabs,
    tag_list: TagList,
    text_item: MenuItem,
    title: Title,
    toggle_item: MenuItem,
    text_block: TextBlock,
    welcome_cta: WelcomeCTA,
    welcome_hero: WelcomeHero,
    // general
    assign: Assign,
    users_included: UsersIncluded,
    rollup_card: RollupCard,
    rollup_card_list: RollupCardList,
    quiz: Quiz,
    // mols
    action_center: ActionCenter,
    assessment_results: SecurityAssessmentResults,
    phishing_report_table: PhishingReportTable,
    stats: Stats,
    step_list: StepList,
    test_report: TestReport,
    vendor_assignment: VendorAssignment,
    // assessment
    assessment_card: AssessmentCard,
    assessment_results_summary: AssessmentResultsSummary,
    assessment_risk_report_card: AssessmentRiskReportCard,
    assessment_security_foundation: AssessmentSecurityFoundation,
    // framework
    framework_flow_group: FrameworkFlowGroup,
    framework_item: FrameworkItem,

    // deprecated
    page_title: Title,
  }

  return componentTypeMap[type]
}
