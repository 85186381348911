<template>
  <FormKit
    v-model="model"
    type="form"
    :classes="{
      form: {
        [`grid sm:grid-cols-${cols}`]: true,
        [`w-full form-width-${width} form-input-width-${_inputWidth} form-align-${align} form-gap-${gap}`]: true,
        'gap-2 sm:gap-4': gap === 'sm',
        'gap-3 sm:gap-6': gap === 'md',
        'gap-4 sm:gap-8': gap === 'lg',
      },
    }"
    :id="_id"
    @submit="(...args) => emit('submit', ...args)"
  >
    <slot>
      <FormKitSchema v-if="schema" :schema :library="formkitLibrary" />
    </slot>

    <template #submit="submitSlotProps">
      <slot name="submit" v-bind="submitSlotProps" />
    </template>
  </FormKit>
</template>

<script setup lang="ts">
import type {
  FormKitNode,
  FormKitFrameworkContext,
  FormKitSchemaNode,
} from '@formkit/core'
import { getNode } from '@formkit/core'
import { formkitLibrary } from '@/utils/formkit'

export interface Props {
  modelValue: any

  align?: 'start' | 'center' | 'end'
  cols?: '1' | '2' | '3' | '4'
  gap?: 'sm' | 'md' | 'lg'
  id?: string
  inputWidth?: 'fixed' | 'full'
  schema?: FormKitSchemaNode[]
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'full'
}

const {
  id,
  schema,
  align = 'start',
  cols = '1',
  gap = 'md',
  inputWidth = 'fixed',
  width = 'full',
} = defineProps<Props>()
const model = defineModel<Props['modelValue']>()
const emit = defineEmits<{
  submit: [data: any, node: FormKitNode]
  validityChange: [valid: boolean]
}>()

const _id = id || useId()
const validity = ref()
const _inputWidth = width !== 'full' ? 'full' : inputWidth

onMounted(() => {
  const form = getNode(_id) as FormKitNode

  watch(
    form.context as FormKitFrameworkContext,
    () => {
      if (validity.value !== !!form.context?.state.valid) {
        validity.value = !!form.context?.state.valid
        emit('validityChange', validity.value)
      }
    },
    { immediate: true },
  )
})
</script>
