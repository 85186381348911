import hash from 'object-hash'
// GENERAL MODALS
import ModalAction from '~/components/modal/ModalAction.vue'
import ModalChangeEmail from '~/components/modal/ModalChangeEmail.vue'
import ModalChangePassword from '~/components/modal/ModalChangePassword.vue'
import ModalConfirm from '~/components/modal/ModalConfirm.vue'
import ModalCreateProspect from '~/components/modal/ModalCreateProspect.vue'
import ModalDelete from '~/components/modal/ModalDelete.vue'
import ModalFlowMarketplace from '~/components/modal/ModalFlowMarketplace.vue'
import ModalFlowPage from '~/components/modal/ModalFlowPage.vue'
import ModalImportUsers from '~/components/modal/ModalImportUsers.vue'
import ModalOnboardingWalkthroughStep from '~/components/modal/ModalOnboardingWalkthroughStep.vue'
import ModalOrgEdit from '~/components/modal/ModalOrgEdit.vue'
import ModalOrgSwitcher from '~/components/modal/ModalOrgSwitcher.vue'
import ModalPagesController from '~/components/modal/ModalPagesController.vue'
import ModalPolicyTemplate from '~/components/modal/ModalPolicyTemplate.vue'
import ModalReleaseNotes from '~/components/modal/ModalReleaseNotes.vue'
import ModalSubscriptionProInfo from '~/components/modal/ModalSubscriptionProInfo.vue'
import ModalSubscriptionUpgrade from '~/components/modal/ModalSubscriptionUpgrade.vue'
import ModalTeamInvite from '~/components/modal/ModalTeamInvite.vue'
import ModalUserProfile from '~/components/modal/ModalUserProfile.vue'
// FLOW MODALS
import FlowModalManageConnection from '~/components/flow/modal/FlowModalManageConnection.vue'

const MODAL_MAP = {
  actionModal: ModalAction,
  changeEmail: ModalChangeEmail,
  changePassword: ModalChangePassword,
  confirm: ModalConfirm,
  createProspect: ModalCreateProspect,
  delete: ModalDelete,
  flowMarketplace: ModalFlowMarketplace,
  flowPage: ModalFlowPage,
  importUsers: ModalImportUsers,
  manageConnection: FlowModalManageConnection,
  onboardingWalkthroughStep: ModalOnboardingWalkthroughStep,
  orgEdit: ModalOrgEdit,
  orgSwitcher: ModalOrgSwitcher,
  pagesController: ModalPagesController,
  policyTemplate: ModalPolicyTemplate,
  releaseNotes: ModalReleaseNotes,
  subscriptionProInfo: ModalSubscriptionProInfo,
  subscriptionUpgrade: ModalSubscriptionUpgrade,
  teamInviteModal: ModalTeamInvite,
  userProfile: ModalUserProfile,
}

export type ModalName = keyof typeof MODAL_MAP

export function useModal() {
  const globalState = useGlobalState()
  const router = useRouter()

  const currentModal = computed(
    () => globalState.value.modalStack[globalState.value.modalStack.length - 1],
  )
  const currentModalOptions = computed(() => currentModal.value.options)

  function afterClose() {
    if (currentModalOptions.value.afterClose)
      currentModalOptions.value.afterClose()
    // remove closed modal from stack
    globalState.value.modalStack = globalState.value.modalStack.slice(0, -1)
  }

  function closeModal() {
    if (currentModal.value) {
      currentModal.value.isOpen = false
      if (router.currentRoute.value.query.modal) {
        removeQueryParam(router.currentRoute.value, ['modal', 'options'])
      }
    }
  }

  function storeDeepModal(modal: ModalName, options: ModalState['options']) {
    navigateTo(getModalDeepLinkTo(modal, options), {
      replace: options?.replace,
    })
  }

  function getModalDeepLinkTo(
    modal: ModalName,
    options: ModalState['options'],
  ) {
    return {
      query: {
        modal,
        ...(options ? { options: JSON.stringify(options) } : {}),
      },
    }
  }

  function handleModalEvent(type: string, payload: any) {
    const handler = currentModalOptions.value.eventHandlers?.[type]
    if (handler) {
      handler(payload)
    } else {
      console.error(`Modal is missing event hanlder: ${type}`)
    }
  }

  const openModal = useThrottleFn(
    async (modal: ModalName, options: ModalState['options'] = {}) => {
      const modalComponent = MODAL_MAP[modal]
      const newHash = hash({ modal, props: Object.keys(options.props || {}) })
      if (
        import.meta.client &&
        modalComponent &&
        !(
          options.singleton &&
          globalState.value.modalStack.find(
            (openModal) => openModal.modalName === modal,
          )
        )
      ) {
        if (options?.deep) storeDeepModal(modal, options)
        if (modal === currentModal.value?.modalName && options.updateInPlace) {
          currentModalOptions.value.props = options.props
        } else {
          const modalState: ModalState = {
            options,
            component: modalComponent,
            hash: newHash,
            isOpen: true,
            modalName: modal,
          }
          globalState.value.modalStack = [
            ...globalState.value.modalStack,
            modalState,
          ]
        }
      } else if (!modalComponent) {
        console.warn('Modal missing:', modal)
      }
    },
    100,
  )

  return {
    currentModal,
    modalStack: computed(() => globalState.value.modalStack),
    afterClose,
    closeModal,
    handleModalEvent,
    getModalDeepLinkTo,
    openModal,
  }
}
