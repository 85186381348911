<template>
  <AtroContent
    :class="[
      'w-full',
      { 'rounded-2xl bg-atro-warm-gray-2 p-6 pb-2': type === 'bubble' },
    ]"
    direction="col"
  >
    <AtroMarkdown class="text-lg text-atro-slate-purple" :markdown="title" />
    <AtroMarkdown
      v-if="subtitle"
      class="mt-2 text-sm text-atro-gray"
      :markdown="subtitle"
    />

    <AtroContent
      :class="[
        'w-full',
        { 'mt-6': type === 'bubble', 'mt-2 space-y-2': type === 'text' },
      ]"
    >
      <FlowAtomOption
        v-for="option in options"
        :class="{
          'mb-4 mr-4': type === 'bubble',
          'w-full': type === 'text',
        }"
        :option
        :type
        :key="option.value"
        :active="option.value === modelValue"
        :has-correct-option="!!correctOption"
        :correct="
          selectedOption?.value === option.value && correctOptionSelected
        "
        :incorrect="
          selectedOption?.value === option.value && !correctOptionSelected
        "
        @click="onOptionSelected(option)"
      />

      <AtroContent
        v-if="showResult"
        class="flex-1 py-1"
        justify="end"
        items="center"
      >
        <AtroBadge
          class="self-end"
          :label="resultBadgeLabel"
          :type="resultBadgeType"
        />
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import type { Props as OptionProps } from '~/components/flow/atom/FlowAtomOption.vue'

export interface Props {
  options: SelectOption[]
  title: string

  correctOption?: SelectOption['value']
  modelValue?: any
  subtitle?: string
  type?: OptionProps['type']
}

const props = defineProps<Props>()
const model = defineModel<Props['modelValue']>()

const { correctOption, options, type = 'bubble' } = props

const selectedOption = ref(
  options.find((option) => option.value === model.value),
)

const correctOptionSelected = computed(
  () => correctOption === selectedOption.value?.value,
)
const showResult = computed(
  () => selectedOption.value && correctOption && type === 'bubble',
)
const resultBadgeLabel = computed(() =>
  correctOptionSelected.value ? 'Correct' : 'Incorrect',
)
const resultBadgeType = computed(() =>
  correctOptionSelected.value ? 'correct' : 'incorrect',
)

function onOptionSelected(option: SelectOption) {
  selectedOption.value = option
  model.value = option.value
}
</script>
