<template>
  <PopoverUserFlowChecks :flow :user :user-flow-report>
    <AtroGridTableCell justify="center">
      <AtroLoading v-if="pending" size="xs" />
      <AtroSpan
        v-else-if="flow && !hasUserFlowReport && !flow.isAssignable"
        class="text-atro-warm-gray"
        size="xl"
        text="–"
      />
      <NuxtLink v-else class="cursor-pointer" :to="linkTo" @click="onCellClick">
        <AtroIcon
          v-if="flow"
          ref="iconEl"
          :class="['size-6', statusColor]"
          :name="statusIcon"
        />
      </NuxtLink>
    </AtroGridTableCell>
  </PopoverUserFlowChecks>
</template>

<script setup lang="ts">
export interface Props {
  flow: Flow

  user?: User
  userFlowReport?: UserFlowReport
}

const props = defineProps<Props>()
const { flow, user, userFlowReport } = props

const iconEl = ref()
const isHovered = useElementHover(iconEl)
const { flowPath } = useFlowMeta(computed(() => props.flow))
const { pending, wrapWithPending } = usePendingWrap()

const linkTo = computed(() =>
  flowNeedsAssignment.value ? undefined : flowPath.value,
)

const allChecksGood = computed(() =>
  userFlowReport?.checks?.every((check) => check.status === 'ok'),
)
const anyChecksWarning = computed(() =>
  userFlowReport?.checks?.some((check) => check.status === 'warn'),
)
const flowNeedsAssignment = computed(
  () => !hasUserFlowReport.value && flow?.isAssignable,
)
const hasUserFlowReport = computed(() => userFlowReport !== undefined)

const statusIcon = computed<IconName>(() => {
  if (userFlowReport?.status === 'ok') {
    return 'circle-check'
  } else if (userFlowReport?.status === 'warning') {
    return 'exclamation-circle'
  } else if (!hasUserFlowReport.value) {
    return isHovered.value ? 'circle-plus-solid' : 'circle-plus-gray'
  } else {
    return 'circle-rotate'
  }
})

const statusColor = computed(() => {
  if (userFlowReport?.status === 'ok') {
    return 'text-atro-green'
  } else if (userFlowReport?.status === 'warning') {
    return 'text-atro-red'
  } else if (!hasUserFlowReport.value) {
    return 'text-primary'
  } else {
    return 'text-atro-blue'
  }
})

const onCellClick = wrapWithPending(async () => {
  if (flowNeedsAssignment.value && user) {
    const fullFlowFetch = await useData<Flow>('orgFlow', { id: flow.id })
    const usersFetch = await useData<User[]>('orgUsers')
    const fullFlow = fullFlowFetch?.data.value
    const users = usersFetch?.data.value
    if (fullFlow && users) {
      const isSigneesFlow = fullFlow.data.signees
      // flows with signees have a different data structure and page slug for assigning users
      const dataKey = isSigneesFlow ? 'signees' : 'assignedUsers'
      const assignPage = fullFlow.pages.find((page) =>
        page.slug.includes(isSigneesFlow ? 'assign-users' : ':assign'),
      )
      if (assignPage) {
        fullFlow.data[dataKey].userIds = [
          ...fullFlow.data[dataKey].userIds,
          user.id,
        ]
        const onAction = useBRActions({
          actionsToInclude: ['flow'],
          deps: ref({ flow: fullFlow }),
          view: 'orgFlow',
        })
        await onAction({ type: 'update_assignees' }, assignPage)
        refreshData('teamDashboard')
      }
    }
  }
})
</script>
