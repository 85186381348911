<template>
  <AtroModal :size="pagesController?.page?.width || 'lg'">
    <AtroAsync :fetch-request :has-empty="false" v-slot="{ data }">
      <PagesController
        :actions-config
        page-type="modal"
        ref="pagesController"
        :animated="false"
        :pages="data.pages"
      >
        <template #prefix="{ currentPage, onAction }">
          <AtroButton
            v-if="currentPage.backAction"
            icon-left="chevron-left"
            size="sm"
            type="fill"
            :text="currentPage.backActionLabel || 'Back'"
            @click="
              onAction({
                type: currentPage.backAction,
                data: currentPage.backActionPayload,
              })
            "
          />
        </template>
      </PagesController>
    </AtroAsync>
  </AtroModal>
</template>
<script setup lang="ts">
export interface ModalFrameworkItemProps {
  fetchRequest: FetchRequest

  actionsConfig?: ActionsConfig
}

const { fetchRequest } = defineProps<ModalFrameworkItemProps>()

const pagesController = ref()
</script>
