<template>
  <AtroContent
    class="h-full w-full pt-6 sm:pl-10 sm:pt-10"
    direction="col"
    wrap="nowrap"
  >
    <AtroModalHeader
      align="left"
      class="!mb-0 pl-4 pr-4 sm:pl-0 sm:pr-8"
      text="Select your next topic"
    >
      <template v-if="!$isMobile.value" #topRight>
        <FormKit
          v-model="searchTerm"
          :id="searchElId"
          type="search"
          suffix-icon="search"
          placeholder="Search"
          wrapper-class="relative right-10 -top-1.5"
        />
      </template>
      <template v-else #below>
        <FormKit
          v-model="searchTerm"
          type="search"
          suffix-icon="search"
          placeholder="Search"
        />
      </template>
    </AtroModalHeader>

    <AtroContent
      shrink
      class="h-full w-full space-x-0 pt-4 sm:space-x-14 sm:pt-10"
      :direction="$isMobile.value ? 'col' : 'row'"
    >
      <!-- Categories -->
      <AtroContent
        wrap="nowrap"
        class="w-full space-x-4 space-y-0 overflow-auto px-4 pb-2 sm:w-36 sm:space-x-0 sm:space-y-3 sm:px-0"
        :direction="$isMobile.value ? 'row' : 'col'"
      >
        <AtroContent
          v-for="selectableCategory in _categories"
          :key="selectableCategory"
          class="group cursor-pointer space-x-2 sm:w-full"
          items="center"
          @click="changeSelectedCategory(selectableCategory)"
        >
          <AtroDot
            v-if="selectableCategory === selectedCategory"
            class="h-2 w-2 bg-primary"
          />
          <AtroSpan
            size="lg"
            :class="[
              selectableCategory === selectedCategory
                ? 'text-primary'
                : 'text-atro-gray group-hover:text-primary',
            ]"
            :text="selectableCategory"
          />
        </AtroContent>
      </AtroContent>

      <!-- Flow Cards -->
      <AtroContent class="relative h-full w-full flex-1">
        <AtroContent
          shrink
          :class="[
            'scroller scroller__warm h-full w-full flex-1 after:w-[calc(100%-2rem)] sm:pr-14',
            { scroller__inset: $isMobile.value },
          ]"
        >
          <FlowList
            class="scroller-push pt-2"
            view="marketplace"
            :flows="sortedFlows"
          />
        </AtroContent>
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  categories: string[]
  flows: OrgFlow[]

  category?: string
}

const props = defineProps<Props>()
const searchElId = useId()
const { meta, k } = useMagicKeys()
const { openMarketplace } = useMarketplace()

const searchTerm = ref('')
const _categories = computed(() => {
  return ['Available', 'Coming Soon', ...props.categories, 'All']
})

const selectedCategory = computed(() => {
  return props.category
    ? _categories.value.find((_category) => _category === props.category)
    : _categories.value[0]
})

const sortedFlows = computed(() => {
  let _flows: Flow[] = props.flows
  if (selectedCategory?.value === 'Available') {
    _flows = _flows.filter(
      (flow) => flow.status !== 'coming_soon' && !flow.selfReported,
    )
  } else if (selectedCategory?.value === 'Coming Soon') {
    _flows = _flows.filter((flow) => flow.status === 'coming_soon')
  } else if (selectedCategory?.value !== 'All') {
    _flows = _flows.filter((flow) => flow.category === selectedCategory?.value)
  }
  if (searchTerm.value) {
    _flows = _flows.filter((flow) =>
      flow.title
        .toLocaleLowerCase()
        .includes(searchTerm.value.toLocaleLowerCase()),
    )
  }

  return sortFlows(_flows, { mode: 'discover' })
})

function changeSelectedCategory(category: string) {
  openMarketplace({ category }, true)
}

watchEffect(() => {
  if (meta.value && k.value) {
    document.getElementById(searchElId)?.focus()
  }
})
</script>
