<template>
  <PageController
    :data
    :deps
    :page
    :page-type
    @action="(action: FlowAction, page: Page) => $emit('action', action, page)"
    @submit="(data: any) => $emit('submit', data)"
  >
    <!-- MOBILE SCORE -->
    <template #mainPrefix>
      <AtroContent
        v-if="page.showScore && $isMobile.value"
        class="mb-4 w-full"
        items="center"
        justify="center"
      >
        <AtroScoreGauge size="sm" :score="deps.flow.score" />
      </AtroContent>
    </template>

    <template #asidePrefix>
      <ScoreCard v-if="page.showScore" type="flat" :score="deps.flow.score" />
    </template>
  </PageController>
</template>

<script setup lang="ts">
export interface Props {
  data: Record<string, any>
  deps: {
    currentOrg: Org
    currentUser: User
    flow: Flow
    users: User[]
    parentOrg: ParentOrg
    viewAs: View
  }
  page: Page
  pageType: 'main' | 'modal'

  showScore?: boolean
}

const { data, page } = defineProps<Props>()
defineEmits<{
  action: [action: FlowAction, page: Page]
  submit: [data: any]
}>()

const { overrideWalkthroughSteps } = useOnboarding()

watchEffect(() => {
  overrideWalkthroughSteps.value = page.walkthroughSteps || []
})

onUnmounted(() => {
  overrideWalkthroughSteps.value = []
})
</script>
