<template>
  <AtroContent
    class="w-full flex-1 sm:min-w-[400px]"
    direction="col"
    wrap="nowrap"
  >
    <AtroContent
      v-if="canAutoAssign"
      class="w-full"
      items="center"
      justify="center"
    >
      <FormKit
        v-model="autoAssign"
        prefix-icon="sparkles"
        inner-class="!text-atro-gray"
        type="switch"
        text="Auto-assign"
        text-position="left"
        :text-semibold="true"
      />
      <FlowAtomInfoTooltip
        class="ml-3"
        placement="top-start"
        :skidding="-40"
        :tooltip="{
          icon: 'sparkles',
          title: 'Auto-assign',
          text: 'We’ll automatically assign this to any future users added to Atro',
        }"
      />
    </AtroContent>

    <AtroContent class="mt-4 w-full">
      <UserAutoComplete
        v-model="assignedUserIds"
        :placeholder="emailInputPlaceholder"
        :users="usersWithoutExclusion"
        @invite-users="onInviteUsers"
      >
        <template #menu>
          <OverflowMenu
            vertical
            class="h-full w-10"
            menu-button-class="rounded-xl rounded-tl-none rounded-bl-none h-full border-2 border-l-0"
            :items="INVITE_OVERFLOW_MENU_ITEMS"
            @action="onInviteOverflowMenuAction"
          />
        </template>
      </UserAutoComplete>
    </AtroContent>

    <AtroContent
      class="mt-4 w-full flex-1 space-y-4 overflow-auto"
      direction="col"
      wrap="nowrap"
    >
      <AtroContent class="w-full" direction="col" wrap="nowrap">
        <AtroContent
          class="sticky top-0 z-1 w-full bg-white pb-2"
          justify="between"
          items="center"
          wrap="nowrap"
        >
          <AtroSpan class="text-atro-gray" text="Assigned" />
          <AtroButton
            v-if="assignedUserIds.length"
            class="!font-normal"
            icon-right="trash"
            icon-size="md"
            type="flat"
            text="Unassign all"
            @click="unassignAll"
          />
        </AtroContent>

        <AtroContent
          v-auto-animate
          class="w-full space-y-2"
          direction="col"
          items="center"
          wrap="nowrap"
        >
          <UserAssignItem
            v-for="(user, i) in assignedUsers"
            :key="i"
            :user
            view="assigned"
            @unassign-user="unassignUser"
          />
          <AtroEmpty
            v-if="!assignedUsers.length"
            class="max-w-md"
            body="You don’t have any team members assigned.
            Select someone from the list below to assign them."
            heading="No users assigned"
            icon="users"
          />
        </AtroContent>
      </AtroContent>

      <AtroContent
        v-if="unassignedUsers.length"
        class="w-full"
        direction="col"
        wrap="nowrap"
      >
        <AtroContent
          class="sticky top-0 z-1 w-full bg-white pb-2"
          items="center"
          justify="between"
          wrap="nowrap"
        >
          <AtroSpan class="text-atro-gray" text="Unassigned" />
          <AtroButton
            class="!font-normal"
            icon-right="plus"
            icon-size="md"
            text="Assign all"
            type="flat"
            @click="assignAll"
          />
        </AtroContent>

        <AtroContent
          v-auto-animate
          class="w-full space-y-2"
          direction="col"
          wrap="nowrap"
        >
          <UserAssignItem
            v-for="(user, i) in unassignedUsers"
            :key="i"
            :user
            view="unassigned"
            :disabled="excludedUserIds.includes(user.id)"
            @assign-user="assignUser"
          />
        </AtroContent>
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  modelValue: {
    autoAssign: boolean
    userIds: string[]
  }
  deps: {
    users: User[]
  }

  canAutoAssign?: boolean
  emailInputPlaceholder?: string
  excludedUserIds?: string[]
  required?: boolean
}

const INVITE_OVERFLOW_MENU_ITEMS: MenuItem[] = [
  {
    label: 'Copy invite link',
    iconLeft: 'link',
    action: 'copyInviteLink',
  },
  {
    label: 'Upload CSV',
    iconLeft: 'upload',
    action: 'uploadCSV',
  },
]

const {
  deps,
  required,
  canAutoAssign = true,
  excludedUserIds = [],
} = defineProps<Props>()
const model = defineModel<Props['modelValue']>({ default: {} })
const emit = defineEmits<{
  inviteUser: [email: string]
  validityChange: [valid: boolean]
}>()

const toast = useToast()
const { openModal } = useModal()
const { mutate: inviteUser } = useMutation<User[]>('sendInvites')
const { copy: copyOrgShareUrl } = useOrgShareUrl()

const allUsers = computed(() => deps.users || [])
const usersWithoutExclusion = computed(
  () => deps.users?.filter((user) => !excludedUserIds.includes(user.id)) || [],
)
const assignedUserIds = computed({
  get() {
    const userIds = model.value?.userIds
    if (required) emit('validityChange', userIds?.length > 0)
    return userIds || []
  },
  set(value) {
    model.value = {
      ...model.value,
      userIds: value,
    }
  },
})
const autoAssign = computed({
  get() {
    return (
      typeof model.value?.autoAssign === 'undefined' || model.value?.autoAssign
    )
  },
  set(value) {
    model.value = {
      ...model.value,
      autoAssign: value,
    }
  },
})
const assignedUsers = computed(() =>
  allUsers.value.filter((user) => assignedUserIds.value.includes(user.id)),
)
const unassignedUsers = computed(() =>
  allUsers.value.filter((user) => !assignedUserIds.value.includes(user.id)),
)

function assignAll() {
  assignedUserIds.value = usersWithoutExclusion.value.map((user) => user.id)
}

function unassignAll() {
  assignedUserIds.value = []
}

function assignUser(user: User) {
  assignedUserIds.value = [...assignedUserIds.value, user.id]
}

function unassignUser(user: User) {
  const index = assignedUserIds.value.findIndex((userId) => userId === user.id)
  assignedUserIds.value?.splice(index, 1)
}

async function onInviteUsers(value: string | string[]) {
  const emails = Array.isArray(value) ? value : value.trim().split(/[ ,]+/)
  try {
    const users = await inviteUser({ emails, role: 'member' })
    users.forEach(assignUser)
    toast.success(emails.length > 1 ? 'Users invited!' : 'User invited!')
  } catch (e: any) {
    if (e?.data?.errors?.user?.email) {
      toast.error('User has already been invited to another organization')
    }
  }
}

function onInviteOverflowMenuAction(actionName: string) {
  switch (actionName) {
    case 'copyInviteLink':
      copyOrgShareUrl()
      break
    case 'importFromGoogle':
      break
    case 'uploadCSV':
      openModal('importUsers', {
        props: {
          onInviteUsers,
        },
      })
      break
  }
}
</script>
